class Slideshow {
    constructor($slideshowContainer) {
        this.$slideshowComponent = $slideshowContainer.parentElement;
        this.$slideshowItemContainer = this.$slideshowComponent.querySelector('.slideshow-item-container')
        this.$slideshowBottomBar = this.$slideshowComponent.querySelector('.slideshow-bottom-bar')
        this.$slideshowControlsPrev = this.$slideshowComponent.querySelector('.slideshow-controls-prev')
        this.$slideshowControlsNext = this.$slideshowComponent.querySelector('.slideshow-controls-next')
        this.$slideshowCounterCurrent =  this.$slideshowComponent.querySelector('.slideshow-counter-current')
        this.$slideshowCounterMax = this.$slideshowComponent.querySelector('.slideshow-counter-max')
        this.$slideshowProgressBar = this.$slideshowComponent.querySelector('.slideshow-progress-bar')
        this.$slideshowItems = this.$slideshowComponent.querySelectorAll('.slideshow-item')
        this.titleElement = this.$slideshowComponent.querySelector('.slideshow-title')
        this.progressElements = null
        this.numberOfSlideshowItems = this.$slideshowComponent.querySelectorAll('.slideshow-item').length / 3
        this.currentSlideshowItemIndex = this.numberOfSlideshowItems + 1
        this.isRunning = false
        this.isMouseDown = false
        this.cursorStartPositionX = null
        this.startTime = null
        this.diffBetweenCurrentAndStartPositionX = null
        this.currentXTranslation = null
        this.isTransitioning = false
        this.stopCount = 0
        this.duration = 5000 + (Math.random() * 10000)
        this.snapPointArray = []
        this.init()
    }

    init() {
        if (this.numberOfSlideshowItems > 1) {
            console.log('init')
            this.getSnapPointArray()
            this.jumpToCurrentIndexWithoutTransition()
            this.setCurrentXTranslation()
            this.addAllEventListeners()
            //this.setSlideshowCounterCurrentInnerHTML()
            //this.setSlideshowCounterMaxInnerHTML()
            this.initProgressBar()
            this.startSlideshow()
        } else {
            this.hideSlideshowBottomBar()
        }
    }

    getSnapPointArray() {
        this.snapPointArray.push(0)
        this.$slideshowItems.forEach((slideshowItem, index) => {
            if (index !== this.$slideshowItems.length - 1) {
                this.snapPointArray.push(this.snapPointArray[index] + slideshowItem.getBoundingClientRect().width + (15))
            }
        })
        console.log(this.snapPointArray)
    }

    jumpToCurrentIndexWithoutTransition() {
        this.$slideshowItemContainer.classList.remove('transition-transform')
        this.$slideshowItemContainer.style.transform = `translateX(-${this.snapPointArray[this.currentSlideshowItemIndex]}px)`
        setTimeout(() => {
            this.$slideshowItemContainer.classList.add('transition-transform')
            this.isTransitioning = false
        })
    }

    setCurrentXTranslation() {
        this.currentXTranslation = this.snapPointArray[this.currentSlideshowItemIndex]
    }

    addAllEventListeners() {
        this.initNavButtonEventListeners()
        this.initMouseButtonEventListeners()
        this.initTouchEventListeners()
        this.initTransitionEventListener()
    }

    initProgressBar() {
        for (let i = 0; i < this.numberOfSlideshowItems; i++) {
            const slideshowProgressElementContainer = document.createElement('div');
            slideshowProgressElementContainer.className = 'slideshow-progress-element-container';

            const slideshowProgressElement = document.createElement('div');
            slideshowProgressElement.className = 'slideshow-progress-element';

            const yellowElement = document.createElement('div');
            yellowElement.className = 'slideshow-progress-element-yellow';
            if (this.duration === 999999999) {
                yellowElement.style.transition = ''
            } else {
                yellowElement.style.transition = `width ${this.duration}ms linear`
            }

            const grayElement = document.createElement('div');
            grayElement.className = 'slideshow-progress-element-gray';
            if (this.duration === 999999999) {
                grayElement.style.transition = ''
            } else {
                grayElement.style.transition = `width ${this.duration}ms linear`
            }

            slideshowProgressElement.appendChild(yellowElement);
            slideshowProgressElement.appendChild(grayElement);
            slideshowProgressElementContainer.appendChild(slideshowProgressElement)


            slideshowProgressElementContainer.addEventListener('click', () => {
                this.handleProgressElementClick(i)
            })

            this.$slideshowProgressBar.appendChild(slideshowProgressElementContainer);
        }
        this.progressElements = Array.from(this.$slideshowProgressBar.querySelectorAll('.slideshow-progress-element'));
        this.setProgressBarColor()
    }

    handleProgressElementClick(index) {
        this.isTransitioning = true
        this.stopSlideshow()

        console.log(index)
        console.log(this.currentSlideshowItemIndex)
        this.currentSlideshowItemIndex = index + this.numberOfSlideshowItems + 1;

/*        let diffToCurrent = Math.abs(index + 1 + this.numberOfSlideshowItems - this.currentSlideshowItemIndex)
        let diffToNext = Math.abs(index + 1 + (2 * this.numberOfSlideshowItems) - this.currentSlideshowItemIndex)
        let diffToLast = Math.abs(index + 1 - this.currentSlideshowItemIndex)


        if (diffToCurrent <= diffToNext && diffToCurrent <= diffToLast) {
            this.currentSlideshowItemIndex = index + this.numberOfSlideshowItems + 1
        } else if (diffToNext <= diffToLast) {
            this.currentSlideshowItemIndex = index + (this.numberOfSlideshowItems * 2) + 1
        } else {
            this.currentSlideshowItemIndex = index + 1
        }*/

        this.$slideshowItemContainer.style.transform = `translateX(-${this.snapPointArray[this.currentSlideshowItemIndex]}px)`
        //this.checkIfEndOrBeginningWasExceeded()
        //this.setSlideshowCounterCurrentInnerHTML()
        this.setCurrentXTranslation()
    }

    initNavButtonEventListeners() {
        if(this.$slideshowControlsPrev) {
            this.$slideshowControlsPrev.addEventListener('click', (e) => this.slideFromCurrentIndex(e, -1))
        }

        if(this.$slideshowControlsNext) {
            this.$slideshowControlsNext.addEventListener('click', (e) => this.slideFromCurrentIndex(e, 1))
        }
    }

    slideFromCurrentIndex(e, slideStep) {
        if (!this.isTransitioning) {
            this.isTransitioning = true
            if (e && this.isRunning) {
                this.stopSlideshow()
            }
            this.currentSlideshowItemIndex += slideStep
            if ((this.currentSlideshowItemIndex - this.numberOfSlideshowItems) <= this.numberOfSlideshowItems) {
                this.setProgressBarColor()
            }
            this.$slideshowItemContainer.style.transform = `translateX(-${this.snapPointArray[this.currentSlideshowItemIndex]}px)`
            this.checkIfEndOrBeginningWasExceeded(true)
            //this.setSlideshowCounterCurrentInnerHTML()
            this.setCurrentXTranslation()
        }
    }

    stopSlideshow() {
        this.isRunning = false
        this.setProgressBarColor()
        this.stopCount += 1
        setTimeout(() => {
            this.stopCount -= 1
            if (!this.isRunning && this.stopCount === 0) {
                this.startSlideshowImmediatly()
            }
        }, this.duration)
    }

    startSlideshow() {
        this.isRunning = true
        setTimeout(() => {
            if (this.isRunning) {
                this.slideFromCurrentIndex(null, 1)
                this.startSlideshow()
            }
        }, this.duration)
    }

    startSlideshowImmediatly() {
        this.isRunning = true
        if (this.isRunning) {
            this.slideFromCurrentIndex(null, 1)
            this.startSlideshow()
        }
    }

    checkIfEndOrBeginningWasExceeded(setProgressBarColor) {
        if (this.currentSlideshowItemIndex - 1 >= this.numberOfSlideshowItems * 2) {
            this.currentSlideshowItemIndex = this.currentSlideshowItemIndex - this.numberOfSlideshowItems
            if (setProgressBarColor) {
                this.setProgressBarColor()
            }
        }

        if (this.currentSlideshowItemIndex <= this.numberOfSlideshowItems) {
            this.currentSlideshowItemIndex = this.currentSlideshowItemIndex + this.numberOfSlideshowItems
            if (setProgressBarColor) {
                this.setProgressBarColor()
            }
        }
    }

    setSlideshowCounterCurrentInnerHTML() {
        this.$slideshowCounterCurrent.innerHTML = `${this.currentSlideshowItemIndex - this.numberOfSlideshowItems}`
    }

    initMouseButtonEventListeners() {
        this.$slideshowItemContainer.addEventListener('mousedown', (e) => this.handleMouseDownEvent(e))
        window.addEventListener('mousemove', (e) => this.handleMouseMoveEvent(e))
        window.addEventListener('mouseup', (e) => this.handleMouseUpEvent(e))
    }

    initTouchEventListeners() {
        this.$slideshowItemContainer.addEventListener('touchstart', (e) => this.handleTouchStartEvent(e))
        window.addEventListener('touchmove', (e) => this.handleTouchMoveEvent(e))
        window.addEventListener('touchend', (e) => this.handleTouchCancelEvent(e))
        window.addEventListener('touchcancel', (e) => this.handleTouchCancelEvent(e))
    }

    handleMouseDownEvent(e) {
        if (e.button === 0) {
            console.log('mousedown')
            this.stopSlideshow()
            this.isTransitioning = false
            //document.addEventListener('contextmenu', (e) => this.handleContextMenuEvent(e));
            this.isMouseDown = true
            this.cursorStartPositionX = e.clientX;
        }
    }

    handleTouchStartEvent(e) {
        e.preventDefault()
        this.stopSlideshow()
        this.isTransitioning = false
        this.isMouseDown = true
        this.cursorStartPositionX = e.touches[0].clientX;
        this.startTime = Date.now()
    }

    handleContextMenuEvent(e) {
        e.preventDefault()
    }

    handleMouseMoveEvent(e) {
        if (this.isMouseDown) {
            this.diffBetweenCurrentAndStartPositionX = this.cursorStartPositionX - e.clientX

            this.$slideshowItemContainer.classList.remove('transition-transform')
            this.$slideshowItemContainer.style.transform = `translateX(${(-(this.currentXTranslation + this.diffBetweenCurrentAndStartPositionX))}px)`
        }
    }

    handleTouchMoveEvent(e) {
        if (this.isMouseDown) {
            this.diffBetweenCurrentAndStartPositionX = this.cursorStartPositionX - e.touches[0].clientX
            this.$slideshowItemContainer.classList.remove('transition-transform')
            this.$slideshowItemContainer.style.transform = `translateX(${(-(this.currentXTranslation + this.diffBetweenCurrentAndStartPositionX))}px)`
        }
    }

    handleMouseUpEvent(e) {
        if (e.button === 0 && this.isMouseDown) {
            this.isMouseDown = false
            document.removeEventListener('contextmenu', (e) => this.handleContextMenuEvent(e));
            this.$slideshowItemContainer.classList.add('transition-transform')


            let newXTranslation = this.snapPointArray[this.currentSlideshowItemIndex] + this.diffBetweenCurrentAndStartPositionX
            let newIndex = this.findClosestIndex(this.snapPointArray, newXTranslation)
            let stepSlide = newIndex - this.currentSlideshowItemIndex
            this.slideFromCurrentIndex(null, stepSlide)

            /*if (this.diffBetweenCurrentAndStartPositionX < -(this.$slideshowItemContainer.getBoundingClientRect().width / 2)) {
                this.slideFromCurrentIndex(null, -1)
            } else if (this.diffBetweenCurrentAndStartPositionX > (this.$slideshowItemContainer.getBoundingClientRect().width / 2)) {
                this.slideFromCurrentIndex(null, 1)
            } else {
                this.$slideshowItemContainer.style.transform = `translateX(${(-(this.currentXTranslation))}px)`
            }*/


            this.diffBetweenCurrentAndStartPositionX = 0

        }
    }
    findClosestIndex(arr, num) {
        return arr.reduce((closestIndex, current, index) =>
            Math.abs(current - num) < Math.abs(arr[closestIndex] - num) ? index : closestIndex, 0);
    }


    handleTouchCancelEvent(e) {
        if (this.isMouseDown) {
            this.isMouseDown = false
            this.$slideshowItemContainer.classList.add('transition-transform')
            const swiped = this.checkIfSwipe()
            if (!swiped) {
                let newXTranslation = this.snapPointArray[this.currentSlideshowItemIndex] + this.diffBetweenCurrentAndStartPositionX
                let newIndex = this.findClosestIndex(this.snapPointArray, newXTranslation)
                let stepSlide = newIndex - this.currentSlideshowItemIndex
                this.slideFromCurrentIndex(null, stepSlide)
                /*if (this.diffBetweenCurrentAndStartPositionX < -(this.$slideshowItemContainer.getBoundingClientRect().width / 2)) {
                    this.slideFromCurrentIndex(null, -1)
                } else if (this.diffBetweenCurrentAndStartPositionX > (this.$slideshowItemContainer.getBoundingClientRect().width / 2)) {
                    this.slideFromCurrentIndex(null, 1)
                } else {
                    this.$slideshowItemContainer.style.transform = `translateX(${(-(this.currentXTranslation))}px)`
                }*/
            }
            this.diffBetweenCurrentAndStartPositionX = 0

        }
    }

    checkIfSwipe() {
        if (Math.abs(this.diffBetweenCurrentAndStartPositionX) > 30) {
            const duration = Date.now() - this.startTime
            const velocity = Math.abs(this.diffBetweenCurrentAndStartPositionX) / duration
            if (velocity > 0.4 && Math.abs(this.diffBetweenCurrentAndStartPositionX) < 1000) {
                if (this.diffBetweenCurrentAndStartPositionX > 0) {
                    this.slideFromCurrentIndex(null, 1)
                } else {
                    this.slideFromCurrentIndex(null, -1)
                }
                return true
            } else {
                return false
            }
        }
    }

    initTransitionEventListener() {
        this.$slideshowItemContainer.addEventListener('transitionend', (e) => this.handleTransitionendEvent(e))
    }

    handleTransitionendEvent(e) {
        this.checkIfEndOrBeginningWasExceeded()
        if (this.currentSlideshowItemIndex === this.numberOfSlideshowItems * 2 || this.currentSlideshowItemIndex === this.numberOfSlideshowItems + 1) {
            this.jumpToCurrentIndexWithoutTransition()
        } else {
            this.isTransitioning = false
        }
    }

    setSlideshowCounterMaxInnerHTML() {
        this.$slideshowCounterMax.innerHTML = `${this.numberOfSlideshowItems}`
    }

    hideSlideshowBottomBar() {
        this.$slideshowBottomBar.style.display = "none"
    }

    setProgressBarColor() {
        this.setAllProgressBarsToGray()
        setTimeout(() => {
            this.startAnimationForCurrentProgressElement()
        })
    }


    setAllProgressBarsToGray() {
        for (let i = 0; i < this.progressElements.length; i++) {
            this.progressElements[i].querySelector('.slideshow-progress-element-yellow').style.transition = 'none'
            this.progressElements[i].querySelector('.slideshow-progress-element-gray').style.transition = 'none'
            this.progressElements[i].querySelector('.slideshow-progress-element-yellow').style.width = '0%'
            this.progressElements[i].querySelector('.slideshow-progress-element-gray').style.width = '100%'
        }
    }

    startAnimationForCurrentProgressElement() {
        setTimeout(() => {

        })
        let index = (this.currentSlideshowItemIndex % this.numberOfSlideshowItems) - 1
        if (index === -1) {
            index += this.numberOfSlideshowItems
        }

        if (this.duration === 999999999) {
            this.progressElements[index].querySelector('.slideshow-progress-element-yellow').style.transition = `width 0ms linear`
            this.progressElements[index].querySelector('.slideshow-progress-element-gray').style.transition = `width 0ms linear`
        } else {
            this.progressElements[index].querySelector('.slideshow-progress-element-yellow').style.transition = `width ${this.duration}ms linear`
            this.progressElements[index].querySelector('.slideshow-progress-element-gray').style.transition = `width ${this.duration}ms linear`
        }
        this.progressElements[index].querySelector('.slideshow-progress-element-yellow').style.width = '100%'
        this.progressElements[index].querySelector('.slideshow-progress-element-gray').style.width = '0%'
    }
}

export { Slideshow }

export default function init() {
    document.querySelectorAll('.slideshow-container').forEach((e) => {
        return new Slideshow(e)
    })
}

import Navigation from "./Navigation.js";
import Contact from "./Contact.js";
import Slideshow from "./Slideshow.js";
import ImageBanner from "./ImageBanner.js";

function init() {
    Navigation();
    Contact();
    setTimeout(() => {
        Slideshow();
    },100)
    ImageBanner();
}

document.addEventListener('DOMContentLoaded', () => {
    init();
})
class Contact {
    constructor(contactComponent) {
        this.contactComponent = contactComponent;
        this.init()
    }

    init() {
        const myLatLng = { lat: 47.53076171875, lng: 7.610843181610107 };
        /*const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 14,
            center: myLatLng,
        });
        const marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: "My Location",
        });*/
    }

}

export default function init() {
    document.querySelectorAll('#contact').forEach(contactComponent => new Contact(contactComponent))
}

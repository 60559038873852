class ImageBanner {
    constructor(element) {
        this.imageBannerComponentElement = element;
        this.imageBannerContainerElement = element;
        this.imageBannerItemContainerElement = element.querySelector('.image-banner-item-container');
        this.imageBannerItems = element.querySelectorAll('.image-banner-item');
        this.numberOfImageBannerItems = this.imageBannerItems.length / 3; // Assuming a 3-item layout
        this.width = 0;
        this.duration = 0;
        this.isSettingBack = false;
        this.imageBannerLinks = this.imageBannerComponentElement.querySelectorAll('a');
        this.init();

    }

    init() {
        this.imageBannerLinks.forEach((link) => {
            link.addEventListener("click", (event) => { console.log('click'); event.preventDefault(); event.stopPropagation(); });
        })
        console.log("init banner");
        setTimeout(() => {
            this.getWidthOfAllItems();
            this.getDuration();
            this.initTransitionEventListener();
            this.setTransitionTransform();
            this.translateToMiddle();
        }, 100);
    }

    getWidthOfAllItems() {
        for (let i = 0; i < this.numberOfImageBannerItems * 2; i++) {
            this.width += this.imageBannerItems[i].getBoundingClientRect().width + 100;
        }
    }

    getDuration() {
        this.duration = this.width * (15 - (Math.random() * 5)); // Example duration calculation
    }

    setTransitionTransform() {
        this.imageBannerItemContainerElement.style.transition = `transform ${this.duration}ms linear`;
    }

    translateToMiddle() {
        this.imageBannerItemContainerElement.style.transform = `translateX(-${this.width}px)`;
    }

    initTransitionEventListener() {
        this.imageBannerItemContainerElement.addEventListener('transitionend', (e) => this.handleTransitionendEvent(e));
    }

    handleTransitionendEvent(e) {
        if (this.isSettingBack) return;
        this.isSettingBack = true;
        this.imageBannerItemContainerElement.style.transition = 'none';
        this.imageBannerItemContainerElement.getBoundingClientRect();
        this.imageBannerItemContainerElement.style.transform = `translateX(0px)`;
        this.imageBannerItemContainerElement.getBoundingClientRect();

        requestAnimationFrame(() => {
            this.setTransitionTransform();
            this.translateToMiddle();
            this.isSettingBack = false;
        });
    }
}

export default function init() {
    document.querySelectorAll('.image-banner-container').forEach(e => {
        console.log('idfinsifn')
        new ImageBanner(e);
    });
}

class Navigation {
    constructor(navigationComponent) {
        this.navigationComponent = navigationComponent
        this.header = this.navigationComponent.closest('header')
        this.logo = this.navigationComponent.querySelector('.logo-image')
        this.subMenuLinks = this.navigationComponent.querySelectorAll('.sub-menu-link')
        this.mobileNavIcons = this.navigationComponent.querySelector('.mobile-nav-icons')
        this.navLinks = this.navigationComponent.querySelectorAll('.nav-link')
        this.init()
    }

    init() {
        this.addEventListeners()
    }

    addEventListeners() {

        window.addEventListener('resize', () => this.handleResizeEvent())

        window.addEventListener('scroll', () => this.handleScrollEvent())

        this.subMenuLinks.forEach((subMenuLink) => {
            subMenuLink.addEventListener("mouseenter", () => {
                this.handleSubMenuLinkMouseEnter(subMenuLink)
            })

            subMenuLink.addEventListener("mouseleave", () => {
                this.handleSubMenuLinkMouseLeave(subMenuLink)
            })
        })

        this.subMenuLinks.forEach((subMenuLink) => {
            subMenuLink.addEventListener('click', () => {
                this.handleSubMenuLinkClick(subMenuLink)
            })
        })

        this.mobileNavIcons.addEventListener('click', () => {
            this.handleMobileNavIconsClick()
        })

        this.navLinks.forEach((navLink) => {
            navLink.addEventListener("click", (e) => {
                this.handleNavLinkClick(navLink)
            })
        })
    }

    handleResizeEvent = () => {
        if (window.innerWidth > 1120) {
            document.body.style.overflowY = ''
        }
    }

    handleScrollEvent = () => {
        if (window.scrollY > 200) {
            if (!this.header.classList.contains('fixed')) {
                this.header.classList.add('fixed')
                setTimeout(() => {
                    this.logo.style.width = '140px'
                    this.header.style.transition = 'opacity 600ms ease-in-out'
                    this.header.style.opacity = '1'
                },300)
            }
        } else {
            if (this.header.classList.contains('fixed')) {
                this.header.classList.remove('fixed')
                this.logo.style.width = ''
                this.header.style.opacity = ''
                this.header.style.transition = ''
            }
        }
    }

    handleSubMenuLinkMouseEnter(subMenuLink) {
        if (window.innerWidth > 1120) {
            let subMenu = subMenuLink.querySelector(".sub-menu");
            if (subMenu) {
                subMenu.classList.add('visible');
            }
        }
    }

    handleSubMenuLinkMouseLeave(subMenuLink) {
        if (window.innerWidth > 1120) {
            let subMenu = subMenuLink.querySelector(".sub-menu");
            if (subMenu) {
                setTimeout(() => {
                    subMenu.classList.remove('visible');
                }, 1000);
            }
        }
    }

    handleMobileNavIconsClick() {
        this.navigationComponent.classList.toggle('active');
        if (this.navigationComponent.classList.contains('active')) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = ''
        }
    }

    handleSubMenuLinkClick(subMenuLink) {
        if (window.innerWidth <= 1120) {
            subMenuLink.classList.toggle('active');
        }
    }

    handleNavLinkClick(navLink) {
        if (window.innerWidth <= 1120) {
            if (navLink.classList.contains('logo') && !this.navigationComponent.classList.contains('active')) {

            } else {
                this.navigationComponent.classList.toggle('active');
                document.body.style.overflowY = ''
            }
        }
    }

}

export default function init() {
    document.querySelectorAll('nav').forEach(navigationComponent => new Navigation(navigationComponent))
}
